export const config = {
  PIRAL_BACKEND: 'https://6casuz27pe.execute-api.eu-central-1.amazonaws.com/sb4/',
  PIRAL_BACKEND_FALLBACK: 'https://api.sb4.olive-team.com/piral/',
  DATA_LAYER: 'https://84al2lne7j.execute-api.eu-central-1.amazonaws.com/sb4/',
  DATA_LAYER_FALLBACK: 'https://api.sb4.olive-team.com/data-layer/',
};

/* export const config = {
  PIRAL_BACKEND: 'https://6casuz27pe.execute-api.eu-central-1.amazonaws.com/sb4/',
  PIRAL_BACKEND_FALLBACK: 'https://cloud.omilab.org/api/piral-backend/',
  DATA_LAYER: 'https://84al2lne7j.execute-api.eu-central-1.amazonaws.com/sb4/',
  DATA_LAYER_FALLBACK: 'https://cloud.omilab.org/api/data-layer/',
}; */