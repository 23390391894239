import * as EventEmitter from "events";
import WiringManager, { WiringEndpoint } from "../WiringManager";
import AbstractDispatcher from "./AbstractDispatcher";
import {
  AbstractEndpoint,
  Adaptor,
  Connector,
  DefaultEndpointTypes,
  Endpoint,
  EventEndpointParameters,
  JSAPIEndpointParameters,
  TYPE,
} from "@olive/oli-types";
import ConnectivityManager from "../ConnectivityManager";
import BroadcastDispatcher from "./BroadcastDispatcher";
import WindowDispatcher from "./WindowDispatcher";
import APIManager from "../APIManager";
import { isEmpty, isString } from "lodash";
import * as JsonataModule from "jsonata";

const jsonata = JsonataModule.default;

const SUPPORTED_TYPES = [
  "olive",
  "event",
  "jsAPI",
  "windowmessage",
  "broadcast",
];

export const CLIENT_DISPATCHER_ID = "client-dispatcher";

export default class ClientDispatcher extends AbstractDispatcher {
  eventManager: EventEmitter;
  apiManager: APIManager;
  connectivityManager: ConnectivityManager;
  broadcastDispatcher: BroadcastDispatcher;
  windowDispatcher: WindowDispatcher;

  constructor(
    wiringManager: WiringManager,
    eventManager: EventEmitter,
    apiManager: APIManager,
    connectivityManager: ConnectivityManager
  ) {
    super(wiringManager);
    this.eventManager = eventManager;
    this.apiManager = apiManager;
    this.connectivityManager = connectivityManager;
    this.broadcastDispatcher = new BroadcastDispatcher(
      wiringManager,
      connectivityManager
    );
    this.windowDispatcher = new WindowDispatcher(
      wiringManager,
      connectivityManager
    );
  }

  async dispatch(props: { id: string; input?: object }): Promise<unknown> {
    let endpoint = this.wiringManager.get(props.id) as AbstractEndpoint;
    console.log(endpoint, endpoint.type);
    switch (endpoint.type) {
      case TYPE.CONNECTOR:
      case "connectorInstance":
        const result = await this.dispatchConnector(
          endpoint as Connector,
          props.input
        );
        return result;
      case TYPE.ADAPTOR:
        return await this.dispatchAdaptor(endpoint as Adaptor, props.input);
      case TYPE.ENDPOINT:
        return await this.dispatchEndpoint(
          endpoint as WiringEndpoint,
          props.input,
          props
        );
      default:
        console.warn(`Endpoint Type ${endpoint.type} not supported`);
        return `Endpoint Type ${endpoint.type} not supported`;
    }
  }

  canDispatch(props: { id: string }): boolean {
    try {
      let { endpoint, type } = this.wiringManager.get(props.id) as Endpoint;
      if (["connectorInstance"].includes(type)) {
        return true;
      }
      const endpointType = endpoint.type;
      return SUPPORTED_TYPES.includes(endpointType);
    } catch (error) {
      return false;
    }
  }

  canDispatchType(type: string): boolean {
    return SUPPORTED_TYPES.includes(type);
  }

  async dispatchConnector(
    connector: Connector,
    input?: object
  ): Promise<unknown> {
    const connectorConfig = connector.connector
      ? connector.connector
      : connector;


    console.log("connectorConfig----------", connectorConfig);
    if (connectorConfig.path) {
      let resultMap = {};
      let previousResult = input;
      for (const pathItem of connectorConfig.path) {
        //TODO FBA: reference does not work as expected in for the connector
        const { ref, endpoint, instanceParameters } = pathItem;
        const resolvedWiring = this.wiringManager.get(endpoint); 

        if(!resolvedWiring || resolvedWiring.endpoint && resolvedWiring.endpoint.type === DefaultEndpointTypes.EVENT) {
          resultMap[ref] = input;
          continue;
        }
        async function resolveInstanceParameters(params: any) {
          const newParams = { ...params };
          for (let key in newParams) {
            if (typeof newParams[key] === 'string' && newParams[key].startsWith('$')) {
              try {
                const expression = newParams[key];
                const result = await jsonata(expression).evaluate(resultMap);
                newParams[key] = result;
              } catch (error) {
                console.error(`Error evaluating jsonata expression: ${newParams[key]}`, error);
              }
            } else if (typeof newParams[key] === 'object' && newParams[key] !== null) {
              newParams[key] = await resolveInstanceParameters(newParams[key]);
            }
          }
          return newParams;
        }
        let resolvedInstanceParameters = instanceParameters;
        if (instanceParameters) {
          resolvedInstanceParameters = await resolveInstanceParameters(instanceParameters);
        }
        const enrichedInput = {
          ...previousResult,
          ...resolvedInstanceParameters,
        };


        console.log("invoking endoint with----------", previousResult, instanceParameters, enrichedInput);

        let result = await this.connectivityManager.invoke({
          id: endpoint,
          instanceParameters: resolvedInstanceParameters,
          input: enrichedInput,
        });

        if (Array.isArray(result)) {
          result = { array: result };
        }

        resultMap[ref] = result;
        previousResult = resultMap[ref];
      }
      return resultMap;
    }

    const sourceResult = await this.invokeSourceEndpoint(
      connectorConfig,
      input
    );

    const result = await this.invokeDestinationEndpoint(
      connectorConfig,
      sourceResult
    );

    return result;
  }

  private async invokeEndpoint(
    endpointConfig: any,
    input?: unknown,
    isSource: boolean = true
  ): Promise<unknown> {
    const endpoint = {
      id: isString(endpointConfig)
        ? endpointConfig
        : isSource
          ? endpointConfig.source.id
          : endpointConfig.destination.instanceID || endpointConfig.destination.id,
      type: isString(endpointConfig)
        ? TYPE.CONNECTOR
        : isSource
          ? endpointConfig.source.type
          : endpointConfig.destination.type,
    };

    if (endpoint.type === TYPE.CONNECTOR || !this.canDispatchType(endpoint.type)) {
      const serverResult = await this.connectivityManager.invoke({
        id: endpoint.id,
        input,
      });
      return serverResult;
    }

    const wiringEndpoint = this.wiringManager.get(endpoint.id) as Endpoint;
    const result = await this.connectivityManager.invoke({
      id: wiringEndpoint.instanceID || wiringEndpoint.id,
      input,
    });

    return result;
  }

  async invokeSourceEndpoint(
    connectorConfig: Connector,
    input?: unknown
  ): Promise<unknown> {
    return this.invokeEndpoint(connectorConfig.source, input, true);
  }

  async invokeDestinationEndpoint(
    connectorConfig: Connector,
    input?: unknown
  ): Promise<unknown> {
    return this.invokeEndpoint(connectorConfig.destination, input, false);
  }

  async dispatchEndpoint(
    endpoint: WiringEndpoint,
    input?: object,
    props?: object
  ): Promise<unknown> {
    console.log(endpoint);

    if (endpoint.endpoint.type === DefaultEndpointTypes.EVENT) {
      const eventEndpoint = endpoint.endpoint as EventEndpointParameters;
      return this.dispatchEvent(eventEndpoint.event.topic, input);
    }
    if (endpoint.endpoint.type === DefaultEndpointTypes.JS_API) {
      let componentInstanceID = "";
      if (props?.instanceParameters) {
        componentInstanceID = props.instanceParameters.componentInstanceID;
      } else if (endpoint.instanceParameters) {
        componentInstanceID = endpoint.instanceParameters.componentInstanceID;
      }
      const { ...endpointRest } = endpoint.instanceParameters;
      const { ...propsRest } = props?.instanceParameters || {};
      const jsAPIEndpoint = endpoint.endpoint as JSAPIEndpointParameters;
      return this.dispatchJSAPI({
        apiName: jsAPIEndpoint.jsAPI.api,
        componentInstanceID,
        input,
        props: { ...endpointRest, ...propsRest },
      });
    }
    if (this.broadcastDispatcher.canDispatch({ id: endpoint.id, endpoint })) {
      return await this.broadcastDispatcher.dispatch({
        id: endpoint.id,
        input,
      });
    }
    if (this.windowDispatcher.canDispatch({ id: endpoint.id, endpoint })) {
      return await this.windowDispatcher.dispatch({
        id: endpoint.id,
        input,
      });
    }
    console.warn(
      "Currently only event endpoints are supported by client dispatcher!"
    );
  }

  async dispatchAdaptor(
    adaptorDefinition: Adaptor,
    input?: object
  ): Promise<unknown> {
    let adaptorResult = input || {};
    if (adaptorDefinition.adaptor.inputMapper) {
      adaptorResult = await this.handleMapper(
        adaptorDefinition.adaptor.inputMapper,
        adaptorResult
      );
    }

    let endpointDefinition = this.wiringManager.get(
      adaptorDefinition.adaptor.adapts
    ) as AbstractEndpoint;
    if (endpointDefinition?.endpoint?.type === DefaultEndpointTypes.JS_API) {
      const { componentInstanceID, ...rest } = adaptorDefinition.instanceParameters;
      const jsAPIEndpoint =
        endpointDefinition.endpoint as JSAPIEndpointParameters;
      adaptorResult = this.dispatchJSAPI({
        apiName: jsAPIEndpoint.jsAPI.api,
        componentInstanceID,
        input: adaptorResult,
        props: { id: endpointDefinition.id, ...rest },
      });
    }

    if (adaptorDefinition.adaptor.outputMapper) {
      adaptorResult = await this.handleMapper(
        adaptorDefinition.adaptor.outputMapper,
        adaptorResult
      );
    }
    return adaptorResult;
  }

  async handleMapper(mapID: string, input: object) {
    let handledResult = input;
    let mapperDefinition = this.wiringManager.get(mapID) as Abstract;
    if (!mapperDefinition) {
      return handledResult;
    }
    const mapperEndpointType = mapperDefinition?.endpoint?.type;
    if (mapperEndpointType === DefaultEndpointTypes.TRANSFORM) {
      const mapperEndpoint = mapperDefinition?.endpoint;
      handledResult = fetchForInline(mapperEndpoint.transform, handledResult);
      return handledResult;
    }
    handledResult = (await this.dispatchEndpoint(
      mapperDefinition,
      input
    )) as object;
    return handledResult;
  }

  dispatchEvent(topic: string, params: object) {
    this.eventManager.emit(topic, params);
  }

  dispatchJSAPI({
    apiName,
    componentInstanceID,
    input,
    props,
  }: {
    apiName: string;
    componentInstanceID: string;
    input: object;
    props: object;
  }) {
    const apiID = componentInstanceID + "-" + apiName;
    const api = this.apiManager.get(apiID);
    if (api) {
      return api({
        ...props,
        ...input
      });
    } else {
      console.error("Could not find api with id " + apiID);
    }
  }
}

async function fetchForInline(
  connectionConfig: any,
  requestParams: any = {}
): Promise<object> {
  if (isEmpty(connectionConfig) || isEmpty(requestParams)) {
    return requestParams;
  }
  let result = requestParams;
  const adapt = connectionConfig.adapt;
  if (!isEmpty(adapt)) {
    try {
      if (typeof adapt === "string") {
        result = await jsonata(adapt as string).evaluate(result);
        console.log(result);
      } else if (typeof adapt === "object") {
        result = await jsonata(`${adapt}`).evaluate(requestParams);
      } else {
        console.warn(`Wrong type of: ${adapt}`);
      }
    } catch (error) {
      console.warn(
        "Failed applying adaption on request data",
        adapt,
        requestParams,
        error
      );
    }
    if (connectionConfig.asObject && typeof result === "string") {
      try {
        result = JSON.parse(result);
      } catch (error) {
        console.error("failed parsing " + result, error);
      }
    }
  }

  return result;
}
