import WiringManager from "../WiringManager";

export default abstract class AbstractDispatcher
{
  wiringManager: WiringManager;
  constructor(wiringManager: WiringManager)
  {
    this.wiringManager = wiringManager;
  }

  abstract dispatch(props: {id: string, input?: unknown}): Promise<unknown>

  abstract canDispatch(props: {id: string}): boolean
}