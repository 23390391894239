import {ApplicationConfigurationFragment, Connector, ConnectorInstance, Endpoint, EndpointInstance, WiringFragment} from "@olive/oli-types";

export function isConnectorInstance(instance: Connector | ConnectorInstance | EndpointInstance | Endpoint ): instance is ConnectorInstance
{
  return instance.type === 'connectorInstance';
}

export function isEndpointInstance(instance: Connector | ConnectorInstance | EndpointInstance | Endpoint ): instance is EndpointInstance
{
  return instance.type === 'endpointInstance';
}

export function isConnectorDefinition(definition: Connector | ConnectorInstance | Endpoint ): definition is Connector
{
  return definition.type === 'connector';
}

export function isEndpointDefinition(definition: Connector | ConnectorInstance | Endpoint ): definition is Endpoint
{
  return definition.type === 'endpoint';
}

export function isWiringFragment(fragment: ApplicationConfigurationFragment): fragment is WiringFragment {
  return fragment.type === 'wiring';
}