import WiringManager from "../WiringManager";
import AbstractDispatcher from "./AbstractDispatcher";
import ConnectivityManager from "../ConnectivityManager";
import {
  Endpoint,
  JSONObject,
  ConnectorInstance,
  BasicInstance,
  DefaultEndpointTypes,
  WindowMessageEndpoint
} from "@olive/oli-types";
import { BroadcastEnvelope } from "@olive/broadcast-util";
import findWindowByOrigin from "./util/getAllIframeWindows";
import getAllIframeWindows from "./util/getAllIframeWindows";

export default class WindowDispatcher extends AbstractDispatcher {
  connectivityManager: ConnectivityManager;
  origins: {
    [origin: string]: {
      origin: string,
      win: MessageEventSource
    }
  }

  constructor(
    wiringManager: WiringManager,
    connectivityManager: ConnectivityManager
  ) {
    super(wiringManager);
    this.connectivityManager = connectivityManager;
    this.origins = {};
    window.addEventListener('message', this.#onMessage.bind(this));
  }

  async #onMessage(message: MessageEvent) {
    console.log("#onMessage", message);
    const envelope = message.data as BroadcastEnvelope;

    if(this.#isRegisterOriginMessage(envelope)) {
      this.#registerOriginMessage(message);
      return;
    }

    console.log("event to dispatch", envelope);

    const allWirings = this.wiringManager.getAll();
    if(!allWirings) {
      return;
    }
    const connectors = Object.keys(allWirings).map(key => allWirings[key]).filter(wiring => wiring.type === "connectorInstance");

  
    const matchingConnectors = connectors.filter(connector => {
      const connectorInstance = connector as ConnectorInstance;
      return !connectorInstance.connector?.path && (connectorInstance.source as BasicInstance).id === envelope.endpointID;
    });
    console.log("matchingConnectors", matchingConnectors);

    const dispatchers = matchingConnectors.map(connector => this.connectivityManager.invoke({
      id: ((connector as ConnectorInstance).destination as BasicInstance).instanceID,
      input: envelope.payload
    }));
    const result = await Promise.all(dispatchers);
    console.log("dispatched: ", result);
  }

  #isRegisterOriginMessage(envelope: BroadcastEnvelope)
  {
    return envelope.topic === "oliveRegisterOrigin";
  }

  #registerOriginMessage(message: MessageEvent)
  {
    console.info("registering new window origin");
    const envelope = message.data as BroadcastEnvelope;
    const payload = envelope.payload as unknown as {id: string, origin: string};
    if(payload.origin && payload.id)
    {
      this.origins[payload.id] = {
        origin: payload.origin,
        win: message.source
      }
    }
    else
    {
      console.error("Missing origin or window in register origin message: ", payload);
    }
  }

  #getEndpoint(id: string | undefined | null): Endpoint | null {
    if (!id) {
      return null;
    }
    return this.wiringManager.get(id) as Endpoint;
  }

  canDispatch(props: { id: string, endpoint: Endpoint | undefined }): boolean {
    const endpoint = !props.endpoint ? this.#getEndpoint(props.id) : props.endpoint;
    if (!endpoint) {
      return false;
    }
    const type = endpoint.endpoint.type;
    return type === DefaultEndpointTypes.WINDOW_MESSAGE;
  }

  async dispatch(props: { id: string; input?: object }){
    
    const endpoint = this.#getEndpoint(props.id) as WindowMessageEndpoint;
    if (!endpoint) {
      console.warn("Endpoint not found: " + props.id);
      return;
    }

    const origin = endpoint.endpoint.windowmessage.origin;
    const originEntry = this.origins[origin];
    if(!originEntry) {
      console.error("Tried to dispatch message to unknown origin: " + origin);
    }

    const message = endpoint.endpoint.windowmessage.topic;

    // TODO need something better to combine input and messgae
    // we can not always send the same format / schema, needs to be configurable
    const envelope = {
      message: message,
      input: props.input
    };

    //@ts-ignore
    originEntry.win.postMessage(envelope, originEntry.origin);

    return props.input;
  }

}