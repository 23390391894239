import {
  DefaultEndpointTypes,
  EventEndpointParameters
} from "@olive/oli-types";
import * as EventEmitter from "events";
import {
  isConnectorInstance
} from "../utils/connectivityUtil";
import WiringManager, { WiringEndpoint } from "./WiringManager";
import AbstractDispatcher from "./dispatchers/AbstractDispatcher";

export default class ConnectivityManager {
  wiringManager: WiringManager;
  eventManager: EventEmitter;
  dispatchers: { [key: string]: AbstractDispatcher };

  constructor(props: {
    wiringManager: WiringManager;
    eventManager: EventEmitter;
  }) {
    this.wiringManager = props.wiringManager;
    this.eventManager = props.eventManager;
    this.dispatchers = {};
    this.setupEventListeners();
  }

  setupEventListeners() {
    const wirings = this.wiringManager.getAll();
    for (let key in wirings) {
      const endpoint = wirings[key];
      // we only care about connector instances here, not about connector definitions because
      // client side events can only exist in connector instances
      if (isConnectorInstance(endpoint)) {
        if(endpoint.connector) {
          //TODO FBA: connector reference does not work as expected in for the connector
          const { path } = endpoint.connector;
          for (const pathItem of path) {
            const resolvedWiring = this.wiringManager.get(pathItem.endpoint);
            if(!resolvedWiring) {
              continue;
            }
            if(resolvedWiring.type === "connector") {
              continue;
            }
            if(resolvedWiring.endpoint.type === DefaultEndpointTypes.EVENT) {
              const componentInstanceID = pathItem.instanceParameters?.componentInstanceID;

              const eventTopic = (
                resolvedWiring.endpoint as EventEndpointParameters
              ).event.topic;
              this.eventManager.on(
                componentInstanceID + "-" + eventTopic,
                this.onEvent.bind(this, {
                  id: endpoint.id,
                  endpoint: endpoint
                })
              );
            }

            continue;
          }
        }
        const connectorInstance = endpoint;
        const source = connectorInstance.source;
        if (!source) {
          continue;
        }
        const sourceEndpointID =
          typeof source === "string" ? source : source.instanceID;
        const sourceEndpoint = wirings[sourceEndpointID] as WiringEndpoint;
        let destination;
        let destinationEndpointID;
        if (!sourceEndpoint) {
          //FBA: Discuss this with olive team, we know make an event after every api call and people can register onto it
          const strippedText: string = sourceEndpointID.replace(
            /^onAfter_(.*)/,
            "$1"
          );
          const sourceEndpoint = wirings[strippedText] as WiringEndpoint;
          if (!sourceEndpoint) continue;
          destination = connectorInstance.destination;
          destinationEndpointID =
            typeof destination === "string"
              ? destination
              : destination.instanceID;
          this.eventManager.on(
            sourceEndpointID,
            this.onEvent.bind(this, destination)
          );

          continue;
        }
        if (sourceEndpoint?.endpoint?.type === DefaultEndpointTypes.EVENT) {
          destination = connectorInstance.destination;
          const componentInstanceID = connectorInstance.source?.instanceParameters?.componentInstanceID;
          destinationEndpointID =
            typeof destination === "string"
              ? destination
              : destination.instanceID;
          const eventTopic = (
            sourceEndpoint.endpoint as EventEndpointParameters
          ).event.topic;
          this.eventManager.on(
            componentInstanceID + "-" + eventTopic,
            this.onEvent.bind(this, {
              id: destinationEndpointID,
              endpoint: destination,
              input: connectorInstance.input,
            })
          );
        }
      }
      // if ("type" in endpoint && endpoint.type === TYPE.CONNECTOR) {
      //   // @ts-ignore we know it's a connector instance because of the check before
      //   const connector = endpoint as ConnectorInstance;
      //   const source = connector.source;
      //   const sourceEndpointID =
      //     typeof source === "string" ? source : source.id;
      //   const sourceEndpoint = wirings[sourceEndpointID] as Endpoint;
      //   if (sourceEndpoint.endpoint.type === DefaultEndpointTypes.EVENT) {
      //     const destination = connector.destination;
      //     const destinationEndpointID = typeof destination === "string" ? destination : destination.id;
      //     const eventTopic = (sourceEndpoint as EventEndpoint).endpoint.event
      //       .topic;
      //     this.eventManager.on(
      //       eventTopic,
      //       this.onEvent.bind(this, destinationEndpointID)
      //     );
      //   }
      // }
    }
  }

  async onEvent(
    {
      id,
      endpoint,
      input: connectorInput = {},
    }: { id: string; endpoint?: any; input?: Object },
    params?: unknown
  ) {
    let input: any = {};
    if (params instanceof FormData) {
      input.formData = params;
    } else if (typeof params === 'object' && params !== null) {
      input = { ...params };
    }

    input = { ...input, ...connectorInput };

    if (endpoint?.input) {
      input = { ...input, ...endpoint.input };
    }

    const result = await this.invoke({
      id,
      input,
    });
    return result;
  }

  async invoke(props: { id: string; input?: unknown; instanceParameters?: any }) {
    for (let key in this.dispatchers) {
      const dispatcher = this.dispatchers[key];
      if (dispatcher.canDispatch({ id: props.id })) {
        return await dispatcher.dispatch(props);
      }
    }
    console.error("No dispatcher found that can dispatch " + props.id);
    throw "No dispatcher found that can dispatch " + props.id;
  }

  registerDispatcher(id: string, dispatcher: AbstractDispatcher) {
    if (this.dispatchers[id]) {
      console.warn("Dispatcher with id " + id + " already registered");
    }
    this.dispatchers[id] = dispatcher;
  }
}
