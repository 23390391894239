import {Connector, ConnectorInstance, Endpoint, EndpointInstance} from "@olive/oli-types";

export type WiringEndpoint  = Endpoint & Omit<EndpointInstance, 'type'>;
export type WiringConnection = Connector | ConnectorInstance;

export type WiringInfo = {
  [key: string]: WiringEndpoint | WiringConnection
}

export default class WiringManager {
  wirings: WiringInfo;

  constructor(wirings: WiringInfo)
  {
    this.wirings = wirings;
  }

  get (id: string)
  {
    return this.wirings[id];
  }

  getAll ()
  {
    return this.wirings;
  }

}