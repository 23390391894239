import WiringManager from "../WiringManager";
import AbstractDispatcher from "./AbstractDispatcher";
import ConnectivityManager from "../ConnectivityManager";
import {
  Endpoint,
  JSONObject,
  ConnectorInstance,
  BasicInstance,
  DefaultEndpointTypes
} from "@olive/oli-types";
import { invoke, GLOBAL_CHANNEL, BroadcastEnvelope } from "@olive/broadcast-util";

export default class BroadcastDispatcher extends AbstractDispatcher {
  connectivityManager: ConnectivityManager;
  broadcastChannel: BroadcastChannel;

  constructor(
    wiringManager: WiringManager,
    connectivityManager: ConnectivityManager
  ) {
    super(wiringManager);
    this.connectivityManager = connectivityManager;
    this.broadcastChannel = GLOBAL_CHANNEL;
    //this.broadcastChannel.onmessage = this.onBroadcast.bind(this);
  }

  async onBroadcast(message: MessageEvent) {
    console.log("onBroadcast", message);
    const envelope = message.data as BroadcastEnvelope;
    console.log("event to dispatch", envelope);

    const allWirings = this.wiringManager.getAll();
    const connectors = Object.keys(allWirings).map(key => allWirings[key]).filter(wiring => wiring.type === "connectorInstance");

    const matchingConnectors = connectors.filter(connector => ((connector as ConnectorInstance).source as BasicInstance).id === envelope.endpointID);
    console.log("matchingConnectors", matchingConnectors);

    const dispatchers = matchingConnectors.map(connector => this.connectivityManager.invoke({
      id: ((connector as ConnectorInstance).destination as BasicInstance).instanceID,
      input: envelope.payload
    }));
    const result = await Promise.all(dispatchers);
    console.log("dispatched: ", result);
  }

  getEndpoint(id: string | undefined | null): Endpoint | null {
    if (!id) {
      return null;
    }
    return this.wiringManager.get(id) as Endpoint;
  }

  canDispatch(props: { id: string, endpoint: Endpoint | undefined }): boolean {
    const endpoint = !props.endpoint ? this.getEndpoint(props.id) : props.endpoint;
    if (!endpoint) {
      return false;
    }
    const type = endpoint.endpoint.type;
    return type === DefaultEndpointTypes.CLIENT_BROADCAST;
  }

  async dispatch(props: { id: string; input?: object }): Promise<BroadcastEnvelope> {
    const inputData = props.input as JSONObject;
    const message = invoke({
      topic: inputData.topic as string,
      payload: inputData.payload as JSONObject
    }, props.id);
    return message;
  }
}