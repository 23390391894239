import React, { ReactElement } from "react";

import { createInstance, createStandardApi, PiletApi, PiletEntry, Piral } from 'piral';
import errors from './layouts/errors';
import APIManager from "./data-layer/APIManager";
import ConnectivityManager from "./data-layer/ConnectivityManager";
import ClientDispatcher, { CLIENT_DISPATCHER_ID } from "./data-layer/dispatchers/ClientDispatcher";
import ServerDispatcher, { SERVER_DISPATCHER_ID } from "./data-layer/dispatchers/ServerDispatcher";
import WiringManager, { WiringInfo } from "./data-layer/WiringManager";
import ExtensionComponent from "./ExtensionComponent";
import pageLayout from "./layouts/pageLayout";
import { RUMProvider } from "./monitoring/RUMProvider";
import PageFactory from "./util/PageFactory";
import PiletLoadTracker from "./util/PiletLoadTracker";
import createDefaultEvents from "./utils/createDefaultEvents";
import DefaultEvents from "./utils/DefaultEvents";
import { EventEmitter } from 'events';

//TODO think about what to with "enriched" component, which has the url, should they also come into olivetpyes?
type ComponentType = {
  url: string,
  component: string,
  id?: string,
  instanceID: string,
  instanceOf: string,
  setupCode: string,
  config: Object
}
type PageType = {
  layout: {
    id?: string,
    instanceOf: string,
    components: {
      [region: string]: ComponentType
    }
  },
  route: string,
  components: ComponentType[]
}
type PagesType = {
  [pageID: string]: PageType
}

type BackendData = {
  pages: {
    [pageID: string]: PageType
  },
  connectivity: WiringInfo
}
type PiralInstanceType = {
  data: BackendData,
  layout?: any, // TODO
  monitoring?: RUMProvider,
  subdomain?: string
}

export default ({ data, layout, monitoring, subdomain }: PiralInstanceType): ReactElement => {

  const url = new URL(document.location.href);
  const urlParams = url.searchParams;
  let configID = urlParams.get("config");
  const urlProps = urlParams.get("props");
  const eventMgr = new EventEmitter();

  const page = url.pathname.replace("/", "");

  console.info("*** page: " + page);

  // console.info("layout before parsing: " + layout);
  // const jsxLayout = parseLayout(layout);
  // console.info("layout after parsing: ", jsxLayout);
  const jsxLayout = pageLayout;

  const piralAPI = createStandardApi();

  const instance = createInstance({
    state: {
      components: jsxLayout,
      errorComponents: errors,
    },
    // @ts-expect-error
    plugins: [piralAPI],
    requestPilets: requestedPilets(data, eventMgr)
  });

  new PiletLoadTracker({
    eventMgr: eventMgr,
    initialPage: instance.context.navigation.path
  });

  // propagate all props for components into piral data so they are accessible by the components for creation
  // Object.keys(data.components).forEach((cmpID) => {
  //   const cmpConfig = data.components[cmpID];
  //   let propVal = cmpConfig?.metaData?.data;
  //   if(!propVal && urlProps)
  //   {
  //     	propVal = JSON.parse(urlProps);
  //   }
  //   instance.root.setData("props_" + cmpConfig.uniqueID, propVal);
  // });
  instance.root.setData("configid", configID);
  instance.root.setData("page", page);
  instance.root.setData("subdomain", subdomain);
  instance.root.setData("urlParams", urlParams);
  createDefaultEvents(eventMgr, instance);
  instance.root.setData("eventMgr", eventMgr);
  const apiMgr = new APIManager();
  instance.root.setData("apiMgr", apiMgr);

  instance.root.setData("reactWrapperComp", ExtensionComponent);

  // TODO temporary until we have figured out how to handle events better
  instance.root.setData("fireEvent", function (componentID: string, topic: string, params?: object) {
    eventMgr.emit(componentID + "-" + topic, params)
  });

  console.log("connectivity should be contained in: ", data);
  setupConnectivityManagers(data, eventMgr, apiMgr);

  registerPages(instance.root, data.pages, eventMgr);
  return <Piral instance={instance} />
}

const registerPages = (app: PiletApi, pages: PagesType, eventMgr: EventEmitter) => {
  for (const [id, value] of Object.entries(pages)) {
    const { route, layout } = value;
    const { components, id: layoutType, instanceOf: layoutInstanceOf } = layout;
    const routeKey = route.replace("/", "");
    app.registerPage(route, PageFactory.createPage({ pageKey: routeKey, layoutType: layoutInstanceOf || layoutType, components: Object.values(components) }));
    for (const region in components) {
      const { config = {}, instanceID, id: componentID, instanceOf, setupCode } = components[region];
      const slotKey = `${routeKey}-${region}`;

      eventMgr.emit(DefaultEvents.ON_PILET_ADDED_TO_PAGE, {
        id: componentID,
        page: route
      });

      //FBA: check why this was introduced again, it doesnt really work
      app.setData(id, slotKey);
      app.setData(`region_${instanceID || componentID}`, slotKey);
      app.setData(`props_${instanceID || componentID}`, config);
      app.setData(`${instanceID}-setupCode`, setupCode);
      app.setData(`${instanceID}-setupData`, {
        uniqueID: instanceID,
        componentID: instanceOf || componentID,
        configID: app.getData("configid"),
        page: routeKey
      })
    }
  }
}



function setupConnectivityManagers(data: BackendData, eventMgr: EventEmitter, apiMgr: APIManager) {
  const wiringManager = new WiringManager(data.connectivity);
  const connectivityManager = new ConnectivityManager({ wiringManager, eventManager: eventMgr });

  connectivityManager.registerDispatcher(CLIENT_DISPATCHER_ID, new ClientDispatcher(wiringManager, eventMgr, apiMgr, connectivityManager));
  connectivityManager.registerDispatcher(SERVER_DISPATCHER_ID, new ServerDispatcher(wiringManager));
}

function requestedPilets({ pages }: { pages: { [key: string]: PageType } }, eventMgr: EventEmitter) {
  return async function () {
    const urlsWithComponents: PiletEntry[] = [];
    for (const page of Object.values(pages)) {
      const { layout } = page;
      const { components } = layout;
      for (const region in components) {
        const component = components[region]
        urlsWithComponents.push(
          getPiletMetaData(component)
        );
      }
      if (page.components) {
        page.components.forEach(component => {
          urlsWithComponents.push(
            getPiletMetaData(component)
          );
        });
      }
    }
    eventMgr.emit(DefaultEvents.ON_CONFIG_LOADED);
    return urlsWithComponents;
  }
}
function getPiletMetaData(component: ComponentType): PiletEntry {
  return {
    link: component.url,
    name: component.instanceID,
    version: "1.0.0",
    spec: "v2",
    custom: {
      id: component.instanceOf || component.id,
      instanceID: component.instanceID
    }
  };
}

